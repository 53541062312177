<template>
  <v-layout class="lay_popup_root" align-center column>
    <v-layout column class="pop-body" align-center>
      <div class="text-container" v-html="popupText" />
      <slot name="middle"></slot>
      <v-layout class="btn-lay">
        <div class="flex_frame">
        <BtnCompo
          v-if="firstBtn"
          :thema="'white-bg'"
          :width="fWidth"
          :marginRight="'12px'"
          :txtBtn="CONST_CANCEL"
          :fontSize="15"
          :fontWeight="'500'"
          :borderRadius="'100px'"
          :onClick="() => {
            $emit('closePopup')
          }" />
          <BtnCompo
            v-if="secondBtn"
            :width="sWidth"
            :txtBtn="secondTxt ? secondTxt : CONST_OK"
            :fontSize="15"
            :fontWeight="'bold'"
            :borderRadius="borderRadius"
            :onClick="() => {
              onClick();
            }" />
          </div>
      </v-layout>
    </v-layout>
  </v-layout>
</template>

<script>
export default {
    props: {
        onClick: {
          default: ()=>{}
        },
        popupText: {
          default: '팝업 메세지',
        },
        firstBtn: {
          default: true,
        },
        secondBtn: {
          default: true,
        },
        secondTxt: {
          default: null,
        },
        borderRadius: {
            default: 6
        },
        fWidth:{
          default:128
        },
        sWidth:{
          default:null
        }
    }, 
    components: {},
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    destroyed() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
@import "../sass/app.scss";
.lay_popup_root {
    position: relative;
    justify-content: center;
    display: flex;
    .pop-body {
        width: 100%;
        padding: 44px 26px 24px 26px;
        background: #FFFFFF;
        border-radius: 6px;
        color: black; 
        .text-container{
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: center;
        }
        .btn-lay{
          width: 100%;
          margin-top: 24px;
        }
    }
}
.flex_frame{
  display: flex;
  width: 100%;
}
</style>
